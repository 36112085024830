<template>
  <div class="contact-cta-banner">
    <div class="contact-cta-banner__background" />

    <div class="contact-cta-banner__content">
      <UiHeader
        :header="header"
        :header-layout="headerLayout"
        :header-position="headerPosition"
        :header-size="headerSize"
        :subheader="subheader"
      />

      <ButtonBase
        :id="headerLink"
        :button="customButtonProps"
        @click="handleCalendarOpen"
      />
    </div>

    <div class="contact-cta-banner__image">
      <UiImg
        :image="image"
        use-image-size
      />
    </div>

    <Calendar
      v-if="displayCalendar"
      :is-open="isCalendarOpen"
      :calendar-id="calendarId"
      @close="handleCalendarClose"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { ButtonBase, UiHeader, UiImg } from '~ui/components'
import Calendar from '~ui/components/Base/Calendar/Calendar.vue'
import { isLinkValid } from '~ui/helpers/linkUtils'
import type { UiCeCaseStudyBannerProps } from '~ui/types'

const props = defineProps<UiCeCaseStudyBannerProps>()

const displayCalendar = computed(() => !isLinkValid(props.button.link))

const calendarId = computed(() => (props.button?.link as string) || null)
const customButtonProps = computed(() => {
  return displayCalendar.value ? { ...props.button, link: '' } : props.button
})

const isCalendarOpen = ref(false)
const handleCalendarOpen = () => isCalendarOpen.value = true
const handleCalendarClose = () => isCalendarOpen.value = false
</script>

<style lang="scss">
.contact-cta-banner {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(2, auto);
  position: relative;
  isolation: isolate;

  &__background {
    position: absolute;
    inset: 0;
    background-color: color(blue-1000);
    border-radius: em(6px);
    pointer-events: none;
    z-index: z-index(below);

    grid-row: 1 / -1;

    @include media-query(sm) {
      grid-row: 2 / 3;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      display: block;
      inset: 0;
      background-repeat: no-repeat;
    }

    &::before {
      right: 50%;
      background-position: 150% center;

      @include media-query(max sm) {
        right: 0;
        bottom: 50%;
        background-position: 80% 120%;
      }

      background-image: url('~ui/assets/background/bg-before.png');
    }

    &::after {
      left: 50%;
      background-position: -60% 25%;

      @include media-query(max sm) {
        left: 0;
        top: 50%;
        background-size: 200%;
        background-position: 40% top;
      }

      background-image: url('~ui/assets/background/bg-after.png');
    }
  }

  &__content {
    color: color(white);
    padding: rem(16px);

    grid-column: 1 / -1;

    @include media-query(sm) {
      grid-column: 1 / 8;
      grid-row: 2 / 3;
      padding: rem(40px);
    }
  }

  & .ui-header {
    &__title {
      font-weight: 600;
      margin: 0;
    }

    &__subtitle {
      color: color(blue-50);
      margin-top: rem(32px);
    }

    margin-bottom: rem(100px);

    @include media-query(max sm) {
      margin-bottom: rem(40px);
    }
  }

  & .button-base {
    @include media-query(max sm) {
      width: 100%;
    }
  }

  &__image {
    display: flex;
    padding-right: rem(16px);
    justify-content: center;

    grid-column: 1 / -1;

    @include media-query(sm) {
      padding-right: rem(40px);
      justify-content: flex-end;

      grid-column: 8 / -1;
      grid-row: 1 / 3;
    }

    & img {
      object-fit: contain;
      object-position: bottom;
      height: 100%;
      width: 100%;

      @include media-query(max sm) {
        width: 80%;
      }
    }
  }
}
</style>
